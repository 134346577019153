<template>
  <v-container v-if="datetimeSelection" class="px-0 py-2">
    <v-row no-gutters>
      <v-col cols="11" class="mb-5">
        <v-select
          :label="$t('datetime')"
          :items="filteredDatetimeSelection"
          :item-text="datetimeFormat"
          return-object
          v-model="selectedDatetime"
          class="py-0"
          hide-details
          :disabled="stepLoading"
          @mouseover.native="isHovered = true"
          @mouseleave.native="isHovered = false"
        >
          <v-icon
            slot="prepend"
            :disabled="disableNextButton"
            @click="previousDate"
          >
            mdi-menu-left
          </v-icon>
          <v-icon
            slot="append-outer"
            :disabled="disablePreviousButton"
            @click="nextDate"
          >
            mdi-menu-right
          </v-icon>
          <v-icon
            slot="prepend-inner"
            v-if="isHovered && showTrashIcon"
            @click.stop="confirmDelete()"
          >
            mdi-delete
          </v-icon>
        </v-select>
        <v-progress-linear
          v-if="stepLoading"
          indeterminate
          color="primary"
          style="width: 250px"
          class="ml-8"
        ></v-progress-linear>
      </v-col>
      <v-col cols="1" class="text-right pt-1">
        <v-dialog
          v-model="filterDialog"
          width="500"
          @click:outside="resetFilter"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              elevation="1"
              fab
              x-small
              color="#757575"
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                {{ filterLogo }}
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="pb-2">
              {{ $t("dateFilter") }}
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-menu
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        :label="$t('startDate')"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        :rules="[validate.isDate]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      @input="startDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endDate"
                        :label="$t('endDate')"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        :rules="[validate.isDate]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      @input="endDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-divider v-if="availableYears.length > 0"></v-divider>
              <v-checkbox
                v-if="availableYears.length > 0"
                :label="$t('toggleAllYears')"
                @click="toggleAllYears"
                v-model="activateAllYears"
                hide-details
                class="pb-2"
              ></v-checkbox>
              <v-row no-gutters class="pb-5">
                <v-col v-for="year in availableYears" :key="year" cols="2">
                  <v-checkbox
                    :value="year"
                    :label="year.toString()"
                    v-model="selectedYears"
                    @click="updateSelectAllYears"
                    hide-details
                    class="pb-2"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-divider v-if="selectedYears.length > 0"></v-divider>
              <v-checkbox
                :label="$t('toggleAllMonth')"
                @click="toggleAllMonth"
                v-model="activateAll"
                hide-details
                class="pb-2"
              ></v-checkbox>
              <v-row no-gutters>
                <v-col
                  cols="2"
                  v-for="month in filteredMonths"
                  :key="month.val"
                >
                  <v-checkbox
                    :value="month.val"
                    :label="month.label"
                    v-model="selectedMonths"
                    hide-details
                    class="pb-2"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-title class="pb-2">
              {{ $t("sensorFilter") }}
            </v-card-title>

            <v-card-text>
              <v-row no-gutters>
                <v-col
                  cols="3"
                  v-for="sensor in defineAvailableSensors"
                  :key="sensor.val"
                >
                  <v-checkbox
                    :value="sensor.val"
                    :label="sensor.labelResolution"
                    v-model="selectedSensors"
                    hide-details
                    class="pb-2"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
              <v-btn color="primary" text @click="resetFilter">
                {{ $t("reset") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="filterDialog = false">
                ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-dialog v-model="confirmationDialog" width="500">
      <v-card>
        <v-card-title> {{ $t("deleteConfirmation") }}</v-card-title>
        <v-card-text
          >{{
            layer.title +
              ":" +
              " " +
              (selectedDatetime && selectedDatetime.datetime
                ? selectedDatetime.datetime.replace("T", "\u00A0\u00A0")
                : "")
          }}
          <v-checkbox
            :label="$t('deleteAllLayers')"
            color="red"
            @change="reloadCard"
          ></v-checkbox>
          <div v-if="deleteAllLayers">
            <ul v-for="layer in layersArray" :key="layer.id">
              <li>{{ layer }}</li>
            </ul>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-progress-circular
            v-if="isDeleting"
            :size="30"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-btn color="red" text @click="deleteScene" :disabled="isDeleting"
            >{{ $t("delete") }}
          </v-btn>
          <v-btn color="primary" text @click="confirmationDialog = false"
            >{{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import SensorUtils from "@/core/utils/sensor.utils";
import Vue from "vue";
import axios from "axios";

let months = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12"
];

let sensors = [
  "SENT2",
  "SENT1",
  "SENT3",
  "LSAT8",
  "LSAT7",
  "LSAT9",
  "SUDOV",
  "VIIRS",
  "SKYST",
  "MODAQ",
  "ENMAP"
]; // for the reset of the filter

export default {
  name: "EoDatetimeSelection",
  props: {
    layerSensorSelection: Array,
    datetimeSelection: Array,
    initSelectedDatetime: {
      type: Object,
      default: null
    },
    aggregationMode: String,
    layer: Object,
    stepLoading: { type: Boolean, default: false }
  },
  data: function() {
    return {
      selectedDatetime: null,
      movieInterval: null,
      filterDialog: false,
      filterDates: [],
      filteredMonths: [
        { val: "01", label: this.$t("month.jan") },
        { val: "02", label: this.$t("month.feb") },
        { val: "03", label: this.$t("month.mar") },
        { val: "04", label: this.$t("month.apr") },
        { val: "05", label: this.$t("month.mai") },
        { val: "06", label: this.$t("month.jun") },
        { val: "07", label: this.$t("month.jul") },
        { val: "08", label: this.$t("month.aug") },
        { val: "09", label: this.$t("month.sep") },
        { val: "10", label: this.$t("month.oct") },
        { val: "11", label: this.$t("month.nov") },
        { val: "12", label: this.$t("month.dec") }
      ],
      selectedMonths: months,
      selectedSensors: sensors,
      startDateMenu: false,
      endDateMenu: false,
      startDate: null,
      endDate: null,
      validate: {
        isDate: value => {
          const pattern = /^\d{4}-\d{2}-\d{2}$/;
          const valid = pattern.test(value) || value == null;
          return valid || this.$t("invalidDate");
        }
      },
      activateAll: true,
      isHovered: false,
      confirmationDialog: false,
      isDeleting: false,
      deleteAllLayers: false,
      layersArray: [],
      selectedYears: this.availableYears,
      activateAllYears: true
    };
  },
  computed: {
    ...mapState("raster", ["activeRasterLayer", "rasterLayer"]),
    ...mapGetters("management", ["activeRegion"]),
    sensorDict() {
      return SensorUtils.getSensorDict();
    },
    disableNextButton() {
      if (this.datetimeSelection) {
        return (
          this.datetimeSelection.indexOf(this.selectedDatetime) ===
          this.datetimeSelection.length - 1
        );
      }
      return false;
    },
    disablePreviousButton() {
      if (this.datetimeSelection) {
        return this.datetimeSelection.indexOf(this.selectedDatetime) === 0;
      }
      return false;
    },
    filterIsOn() {
      return (
        (this.selectedMonths.length < 12) |
        !!this.startDate |
        !!this.endDate |
        (this.availableYears.length !== this.selectedYears.length)
      );
    },
    filterLogo() {
      if (this.filterIsOn) {
        return "mdi-filter-check";
      }
      return "mdi-filter";
    },
    filteredDatetimeSelection() {
      let selection = this.datetimeSelection;

      if (this.startDate) {
        selection = selection.filter(
          item => new Date(item.datetime) >= new Date(this.startDate)
        );
      }

      if (this.endDate) {
        selection = selection.filter(
          item => new Date(item.datetime) <= new Date(this.endDate)
        );
      }

      if (this.selectedSensors) {
        selection = selection.filter(item =>
          this.selectedSensors.includes(item.sensor)
        );
      }

      if (this.selectedMonths) {
        selection = selection.filter(item =>
          this.selectedMonths.includes(item.datetime.slice(5, 7))
        );
      }

      if (this.selectedYears) {
        selection = selection.filter(item =>
          this.selectedYears.toString().includes(item.datetime.slice(0, 4))
        );
      }

      return selection;
    },

    defineAvailableSensors() {
      // add Sensor to Dict, if not available
      this.layerSensorSelection.forEach(layerSensor => {
        if (!sensors.includes(layerSensor) && layerSensor != "all") {
          this.sensorDict.push({
            val: layerSensor,
            labelResolution: layerSensor
          });
          this.selectedSensors.push(layerSensor);
        }
      });
      // only provide sensors that are also available in the layer
      const result = this.sensorDict.filter(sensorObject =>
        this.layerSensorSelection.includes(sensorObject.val)
      );
      return result;
    },

    showTrashIcon() {
      const client = Vue.prototype.$appConfig.keycloakClient;
      const roles = this.$keycloak.resourceAccess?.[client]?.roles;
      const areThereData = this.filteredDatetimeSelection.length > 0;

      if (areThereData) {
        let hasEomapOrAdminRole = false;
        if (roles) {
          hasEomapOrAdminRole = roles.includes("admin");
        }
        const hasOwner = this.activeRegion.properties.owner;

        return hasEomapOrAdminRole || hasOwner;
      }
      return false;
    },
    availableYears() {
      const years = this.datetimeSelection.map(item => {
        const dateObject = new Date(item.datetime);
        if (!isNaN(dateObject.getTime())) {
          return dateObject.getFullYear();
        }
        return null;
      });
      return Array.from(new Set(years.filter(year => year !== null)));
    }
  },
  methods: {
    ...mapActions("raster", ["setFilteredDatetimeSelection"]),
    nextDate() {
      const selection = this.filteredDatetimeSelection;
      const selected = this.selectedDatetime;
      const selectedPeriodIndex = selection.indexOf(selected);
      if (selectedPeriodIndex > 0) {
        this.selectedDatetime = selection[selectedPeriodIndex - 1];
      } else if (selectedPeriodIndex === 0) {
        this.selectedDatetime = selection[selection.length - 1];
      }
    },
    previousDate() {
      const selection = this.filteredDatetimeSelection;
      const selected = this.selectedDatetime;
      const selectedPeriodIndex = selection.indexOf(selected);
      if (selectedPeriodIndex < selection.length - 1) {
        this.selectedDatetime = selection[selectedPeriodIndex + 1];
      }
    },
    datetimeFormat: item => {
      const dateTime = item.datetime.replace("T", "\u00A0\u00A0\u00A0\u00A0");
      return dateTime;
    },
    resetFilter() {
      this.selectedSensors = sensors;
      this.selectedMonths = months;
      this.startDate = null;
      this.endDate = null;
      this.selectedYears = this.availableYears;
      this.activateAllYears =
        this.availableYears.length === this.selectedYears.length;
    },
    toggleAllMonth() {
      if (this.activateAll) {
        this.selectedMonths = months;
      } else {
        this.selectedMonths = [];
      }
    },
    toggleAllYears() {
      if (this.activateAllYears) {
        this.selectedYears = this.availableYears;
      } else {
        this.selectedYears = [];
      }
    },
    updateSelectAllYears() {
      this.activateAllYears =
        this.availableYears.length === this.selectedYears.length;
    },
    reloadCard() {
      this.deleteAllLayers = !this.deleteAllLayers;

      if (this.deleteAllLayers === true) {
        this.layersArray = [];

        for (const layer of this.rasterLayer) {
          this.layersArray.push(layer.title);
        }
      }
    },
    confirmDelete() {
      this.confirmationDialog = true;
    },
    async deleteScene() {
      this.isDeleting = true;

      const regionId = this.activeRasterLayer.regionId;
      const dt = this.selectedDatetime.datetime;

      if (this.deleteAllLayers) {
        for (const layer of this.rasterLayer) {
          const layerId = layer.layerId;
          const url = `/userdata/raster-data/region/${regionId}/layer/${layerId}/datetime/${dt}`;

          try {
            await axios.delete(url);
          } catch {
            // do nothing
          }
        }
      } else {
        const layerId = this.activeRasterLayer.layerId;
        const url = `/userdata/raster-data/region/${regionId}/layer/${layerId}/datetime/${dt}`;
        await axios.delete(url);
      }

      this.isDeleting = false;

      this.confirmationDialog = false;
      const index = this.filteredDatetimeSelection.findIndex(
        step => step.datetime === dt
      );
      this.$emit("reloadDateTimes", index);
    }
  },
  watch: {
    initSelectedDatetime() {
      this.selectedDatetime = this.initSelectedDatetime;
    },
    filteredDatetimeSelection: function(filteredDatetimeSelection) {
      const sameDate = filteredDatetimeSelection.find(
        item => item?.datetime === this.initSelectedDatetime?.datetime
      );
      if (sameDate) {
        this.selectedDatetime = sameDate;
      } else {
        this.selectedDatetime = filteredDatetimeSelection[0];
      }
      this.setFilteredDatetimeSelection(this.filteredDatetimeSelection);
    },
    selectedDatetime: {
      handler(value) {
        this.$emit("selectedDatetime", value);
      },
      immediate: true
    },
    availableYears() {
      this.selectedYears = this.availableYears;
    }
  },
  beforeMount() {
    this.selectedDatetime = this.initSelectedDatetime;
    this.selectedYears = this.availableYears;
  }
};
</script>

<style></style>
